import {
  AdCreativeStatus,
  CampaignStatus,
  CampaignUnifiedStatus,
  components,
  EmailCreativeStatus,
} from "@openapi";
import dayjs from "dayjs";

export const getUnpublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status === EmailCreativeStatus.draft ||
        status === EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status === AdCreativeStatus.unpublished
    ),
  };
};

export const getPublishedCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return {
    emails: campaign.email_creatives.filter(
      ({ status }) =>
        status !== EmailCreativeStatus.draft &&
        status !== EmailCreativeStatus.unpublished
    ),
    ads: campaign.ad_creatives.filter(
      ({ status }) => status !== AdCreativeStatus.unpublished
    ),
  };
};

export const areAllCreativesPublished = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return (
    getPublishedCreatives(campaign).emails.length ===
      campaign.email_creatives.length &&
    getPublishedCreatives(campaign).ads.length === campaign.ad_creatives.length
  );
};

export const hasAnyCreatives = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  return (
    campaign.email_creatives.length > 0 || campaign.ad_creatives.length > 0
  );
};

export const getCampaignUnifiedStatus = (
  campaign: components["schemas"]["CampaignSchema"]
) => {
  //   - "Planned" - campaign recs that haven't been actioned and PLANNED campaigns. Date is unset or in the future.
  //   - "Designed" - these are campaigns where graphics have been generated but no emails have been published
  //   - "Launched" - these are campaigns that have at least one email published to Klaviyo
  //   - "Past" - Recs that were never actioned for dates now in the past
  //   - "All Current" - default, no status passed. exclude past and launched campaigns
  const hasCreatives = hasAnyCreatives(campaign);
  const isPast = dayjs(campaign.date)
    .startOf("day")
    .isBefore(dayjs().startOf("day"));

  // NOTE: Make sure to match this with logic in campaign/api.py
  let status = null;
  if (campaign.status === CampaignStatus.planned) {
    if (isPast) {
      status = CampaignUnifiedStatus.past;
    } else {
      status = CampaignUnifiedStatus.planned;
    }
  } else if (hasCreatives) {
    if (campaign.email_creatives.every((creative) => !creative.published_at)) {
      status = CampaignUnifiedStatus.designed;
    } else {
      status = CampaignUnifiedStatus.launched;
    }
  }

  return status;
};

export const getCampaignUnifiedStatusFromRecommendation = (
  recommendation: components["schemas"]["CampaignRecommendationSchema"]
) => {
  const recommendationDate = dayjs(recommendation.date).startOf("day");
  const today = dayjs().startOf("day");

  if (recommendationDate.isBefore(today)) {
    return CampaignUnifiedStatus.past;
  }
  return CampaignUnifiedStatus.planned;
};
