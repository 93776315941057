import CampaignProductsPreview from "../common/CampaignProductsPreview";
import HomeCampaignCardLabels from "../common/HomeCampaignCardLabels";
import { campaignTypeContent } from "../draftSection/CampaignDraftCard";
import { campaignRecommendationTypeContent } from "./RecommenderCard";
import { CampaignStatus, components } from "@openapi";
import { Flex, Text, IconButton, Tooltip, Dialog } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Calendar1, MailIcon, Trash2 } from "lucide-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowRightIcon } from "~/assets/icons";
import DraperPill from "~/components/core/DraperPill";
import DraperText, { DraperHeading } from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import DialogHeader from "~/components/core/dialog/DialogHeader";
import DatePicker from "~/components/core/inputs/DatePicker";
import MetaLogo from "~/components/logos/MetaLogo";
import {
  useActiveBrandID,
  useAuthenticatedUserState,
} from "~/contexts/CurrentUserContext";
import useCampaignDeleteMutation from "~/hooks/campaign/useCampaignDeleteMutation";
import { useCampaignUpdateMutationInHomePage } from "~/hooks/campaign/useCampaignUpdateMutation";
import {
  CAMPAIGN_OVERVIEW_ROUTE,
  NEW_CAMPAIGN_EDIT_ROUTE,
} from "~/routes/constants";
import {
  getCampaignUnifiedStatus,
  hasAnyCreatives,
} from "~/utils/campaign/utils";

const StyledPill = styled(DraperPill)`
  position: relative;
  top: 4px;
`;

const Image = styled.img<{
  $aspectRatio: string;
  $roundCorners: "left" | "right" | "all";
  height: string;
  width: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.$aspectRatio};
  object-fit: cover;
  object-position: top;
  border-radius: ${({ $roundCorners }) => {
    if ($roundCorners === "left") {
      return "12px 0 0 12px";
    }
    if ($roundCorners === "right") {
      return "0 12px 12px 0";
    }
    return "12px";
  }};
  border: none;
`;

const Card = styled(Flex)`
  padding: 20px;
  border: 1px solid var(--border-primary);
  border-radius: 32px;
  background-color: var(--primary-white);
  box-shadow: 0px 4px 8px 0px #0000000a;
`;

const HomeCampaignCard = ({
  campaign,
  onDeleteCallback,
  onUpdateCallback,
}: {
  campaign: components["schemas"]["CampaignSchema"];
  onDeleteCallback?: () => void;
  onUpdateCallback?: () => void;
}) => {
  const queryClient = useQueryClient();
  const user = useAuthenticatedUserState();
  const isInternalUser = user.isInternal;
  const activeBrandID = useActiveBrandID();
  const navigate = useNavigate();

  // Variables
  const status = getCampaignUnifiedStatus(campaign);

  // State
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Mutations
  const { updateCampaign, isUpdatingCampaign } =
    useCampaignUpdateMutationInHomePage({
      onSuccess: () => {
        onUpdateCallback?.();
      },
    });

  const { mutate: deleteCampaign, isPending: isDeletingCampaign } =
    useCampaignDeleteMutation({
      campaignId: campaign.id,
      onSuccess: () => {
        setIsDeleteDialogOpen(false);
      },
    });

  const dateComponent = (
    <DatePicker
      initialDate={campaign.date ?? undefined}
      onSelect={(formattedDate) => {}}
      onConfirm={(formattedDate) => {
        updateCampaign({
          date: formattedDate,
          brand_id: activeBrandID,
          campaign_id: campaign.id,
        });
      }}
      triggerComponent={
        <AppButton
          variant="outlined"
          radius="full"
          disabled={isUpdatingCampaign}
        >
          <Flex gap="2" align="center" px="12px">
            <Calendar1 size={20} />
            <Text size="3">
              {campaign.date
                ? dayjs(campaign.date).format("MMM D, YYYY")
                : "Set Date"}
            </Text>
          </Flex>
        </AppButton>
      }
    />
  );

  const previewImages = useMemo(() => {
    const emailImages = campaign.email_images ?? [];
    const adImages = campaign.ad_images ?? [];
    return [emailImages[0], adImages[0], emailImages[1], adImages[1]]
      .filter((image) => !!image)
      .slice(0, 2);
  }, [campaign.email_images, campaign.ad_images]);

  const imageComponent =
    campaign.status === CampaignStatus.planned ? (
      <CampaignProductsPreview productsData={campaign.items} />
    ) : (
      <>
        <Image
          width="110px"
          height="220px"
          $aspectRatio="0.5"
          $roundCorners={previewImages[1] ? "left" : "all"}
          src={previewImages[0] ?? ""}
          alt=""
        />
        {previewImages[1] && (
          <Image
            width="110px"
            height="220px"
            $aspectRatio="0.5"
            $roundCorners="right"
            src={previewImages[1]}
            alt=""
          />
        )}
      </>
    );

  const IconComponent = campaign.campaign_recommendation
    ? campaignRecommendationTypeContent[
        campaign.campaign_recommendation.item_set_type
      ].icon
    : campaignTypeContent[campaign.type].icon;

  const metadata =
    campaign.status === CampaignStatus.planned || !hasAnyCreatives(campaign) ? (
      <DraperText
        size="4"
        variant="secondary"
        wordBreak="break-word"
        lineHeight="26px"
      >
        <StyledPill
          flexShrink="0"
          backgroundColor="var(--black-4, #0000000A)"
          mr="10px"
          height="26px"
          align="baseline"
          icon={
            <IconComponent
              size={20}
              strokeWidth={1.25}
              color="var(--primary-vibrant-orange)"
            />
          }
        >
          {campaign.campaign_recommendation
            ? campaignRecommendationTypeContent[
                campaign.campaign_recommendation.item_set_type
              ].title
            : campaignTypeContent[campaign.type].title}
        </StyledPill>

        <Text size="3">
          {campaign.campaign_recommendation?.reasoning_description}
        </Text>
      </DraperText>
    ) : (
      <Flex direction="row" gap="3" align="center">
        {(campaign.channels?.emails ?? 0) > 0 ? (
          <Flex direction="row" gap="2" align="center">
            <MailIcon size={20} />
            {campaign.channels?.emails} Email
            {campaign.channels?.emails && campaign.channels?.emails > 1
              ? "s"
              : ""}
          </Flex>
        ) : null}

        {(campaign.channels?.meta_ads ?? 0) > 0 ? (
          <Flex direction="row" gap="2" align="center">
            <MetaLogo color="rgb(92, 88, 88)" size={24} />
            {campaign.channels?.meta_ads} Ad
            {campaign.channels?.meta_ads && campaign.channels?.meta_ads > 1
              ? "s"
              : ""}
          </Flex>
        ) : null}
      </Flex>
    );

  return (
    <Card
      direction="row"
      position="relative"
      gap="5"
      overflow="hidden"
      minWidth="780px"
    >
      <Flex style={{ borderRadius: "12px" }} flexShrink="0">
        {imageComponent}
      </Flex>

      <Flex
        direction="column"
        gap="5"
        overflow="hidden"
        width="100%"
        flexGrow={"1"}
        justify="between"
      >
        <Flex direction="column" gap="3">
          <Flex direction="row" gap="3">
            {status && <HomeCampaignCardLabels status={status} />}
            <DraperHeading size="6">{campaign.title}</DraperHeading>
          </Flex>
          <DraperText
            size="4"
            clamp={2}
            wordBreak="break-word"
            weight="medium"
            variant="secondary"
          >
            {campaign.campaign_recommendation?.description ||
              campaign.brand_provided_description}
          </DraperText>

          {metadata}
        </Flex>

        <Flex direction="row" gap="5" justify="between" align="center">
          <AppButton
            onClick={() => {
              if (campaign.status === CampaignStatus.planned) {
                navigate(NEW_CAMPAIGN_EDIT_ROUTE.replace(":id", campaign.id));
              } else {
                navigate(CAMPAIGN_OVERVIEW_ROUTE.replace(":id", campaign.id));
              }
            }}
            mt="auto"
            variant="soft"
          >
            <Flex direction="row" gap="2" align="center">
              <Text size={"3"}>View Campaign</Text>
              <ArrowRightIcon size={20} />
            </Flex>
          </AppButton>
          <Flex
            gap="3"
            align="center"
            style={{
              color: "var(--primary-deep-purple)",
            }}
          >
            {dateComponent}

            {isInternalUser && ( // Remove this once functionality is added
              <Tooltip content="Delete">
                <IconButton
                  variant="outline"
                  color="gray"
                  aria-label="Delete Campaign"
                  radius="full"
                  size="4"
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={isDeletingCampaign}
                >
                  <Trash2 size={18} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Dialog.Root
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
      >
        <Dialog.Content style={{ maxWidth: 450 }}>
          <DialogHeader title="Delete Campaign" />
          <Dialog.Description size="2" mb="4">
            Are you sure you want to delete this campaign,{" "}
            <strong>{campaign.title}</strong>?
          </Dialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <AppButton variant="outlined" radius="large">
                Cancel
              </AppButton>
            </Dialog.Close>
            <AppButton
              radius="large"
              variant="dark"
              onClick={() => {
                deleteCampaign();
                onDeleteCallback?.();
              }}
            >
              Delete
            </AppButton>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </Card>
  );
};

export default HomeCampaignCard;
